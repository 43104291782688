
/* Import Lato font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700');

body {
  
  overflow-x: hidden; /* Prevent horizontal scrolling */


/* App.css */
/* Sign up content styles */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  margin: 0 auto;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}



.boxPic {
  cursor: pointer;
  transition: transform 0.2s;
}

.boxPic:hover {
  transform: scale(1.05);
}
.signup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  margin-top: 2rem;

}


.free {
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.fee{
  align-content: center;
  justify-content: center;
  list-style-type: none
}





.signup-content h2 {
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.signup-form input {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}



.signup-form button:hover {
  background-color: #555555;
}
.footer.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
  text-decoration: none;


}
.address-link{
  text-decoration: none;
  color: white;
}
.address {
  color: white;
}

.address a{
  color: white;
  text-decoration: none;

}  
.phone {
  color: white;
  text-decoration: none;
  align-items: center;
}
.phone-number{
  color: white;
  text-decoration: none;
justify-content: center;
}
.contact-contact-info a{
  text-decoration: none;
  color: white;


}

.email-address{
  color: white;
  text-decoration: none;
  justify-content: center;
  margin-right: .5rem;

}

.PhoneIcon{
  color: white;
  justify-content: center;
}
.contact-info{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  
  
}
.foot-phone-info{
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  text-decoration: none;
  
}
.span.address{
  color:white
}
.contact-phone-link{

text-decoration: none;
justify-content: left;
}
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
}

.Paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  margin-left: 2rem;
}

.contact-info {
  justify-content: flex-start;

}
/* Hide scrollbar for Chrome, Safari and Opera */
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html, body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



/* Reset default styles */
body.my-class { /* Add a valid identifier here, such as a class or an ID */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden; 
}
.contact-link{
  color: black;
  text-decoration: none;

}

/* Header styles */
.header {
  background-color: #333;
  color: white;
  padding: 1rem 0;
}

/* Container for main content */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;

}


/* Branding styles */
#branding h1 {
  margin: 0;
}



.tool {
  background-color: #333;

}
.benefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  border-radius: 20px;
}

@media (min-width: 768px) {
  .benefits {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
  .benefits > div {
    flex: 1 1 50%;
  }
  
  .subTitle {
    font-size: 1rem;
    font-weight: medium;
    color: gray
  }



/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333; /* Match header background */
  color: white;
  border-radius: 5px;
  width: 100%; /* Ensure full width */
}


/* Navigation links */
.nav-links {
  display: flex;
  gap: 1rem;
  list-style: none;
  align-items: left;
}
.desktop-menu {
  display: flex;
  gap: 1rem;
  list-style: none;
}

.desktop-menu a:hover {
  background-color: #555555;
}


.nav-links a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem; /* Increased padding for easier clicking */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;

}




/* Hamburger icon styles */
.hamburger {
  cursor: pointer;
  outline: none;
  background-color: #333;
  width: 3rem;

  

  
}
.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px;
  transition: all 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #333;
    position: absolute;
    top: 60px; /* Adjust based on your navbar height */
    left: 0;
    padding: 1rem;
  }

  .nav-links.active {
    display: flex;
  }
}







/* Showcase section styles */

/* Showcase section styles */
#showcase {
  background: url('../public/images/banner.png') no-repeat center center/cover fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
}

@media (max-width: 768px) {
  #showcase {
    background: url('../public/images/banner.png') no-repeat center center/cover fixed;
    height: 50vh; /* Adjust height for mobile */
    width: 100vw;
    background-attachment: scroll; /* Disable fixed background on mobile */
    margin-top: 7rem; /* Ensure margin is consistent on mobile */
  }
}

#facilityShowcase {
  background: url('./images/facHeader.jpg') no-repeat center center/cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
}

@media (max-width: 768px) {
  #facilityShowcase {
    background: url('./images/facHeader.jpg') no-repeat center center/cover;
    height: 50vh; /* Adjust height for mobile */
    width: 100vw;
    background-attachment: scroll; /* Disable fixed background on mobile */
    margin-top: 7rem; /* Ensure margin is consistent on mobile */
  }
}






#contactShowcase {
  background: url('./images/contact.jpeg') no-repeat center center/cover fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
}

@media (max-width: 768px) {
  #contactShowcase {
    background: url('./images/contact.jpeg') no-repeat center center/cover fixed;
    height: 50vh; /* Adjust height for mobile */
    width: 100vw;
    background-attachment: scroll; /* Disable fixed background on mobile */
    margin-top: 7rem; /* Ensure margin is consistent on mobile */
  }
}

#kenpoShowcase {
  position: relative;
  height: 100vh;
  width: 120vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
  overflow: hidden;
  margin-top: 1rem;
}

#kenpoShowcase video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the video is behind the content */
}

@media (max-width: 768px) {
  #kenpoShowcase {
    height: 50vh; /* Adjust height for mobile */
    width: 100vw;
    height: 50vh; /* Adjust height for mobile */
    margin-top: auto ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1rem;

  }

  #kenpoShowcase video {
    height: auto; /* Adjust video height for mobile */
  }
}
}


.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.visible {
  opacity: 1;
}





















/* Main content area */
main.container {
  padding: 2rem;
}

/* Card component styles */
.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
}

/* Footer styles */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  justify-content: left;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
  text-decoration: none;
}
.footer a {
  text-decoration: none;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 4rem; /* Adjust the top position for better alignment */
    right: 1rem; /* Adjust the right position for better alignment */
    background-color: #333;
    border-radius: 5px;
    z-index: 1000;
    width: 100%; /* Full width for dropdown */
  }
  
  .nav-links.open {
    display: flex;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align hamburger icon to the right */
    margin-right: 1rem; /* Add margin to the right of hamburger icon */
    outline: nonex;
  }
}
/*boxing phot styles*/
.boxPic {
  display: flex-center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  border-radius: 20px;
}

.carousel .slide img {
  transition: opacity 0.5s ease-in-out;
  opacity: 0; /* Adjust this value as needed */
  border-radius: 20px;

}

.carousel .slide.selected img {
  opacity: 1;
  border-radius: 20px;

}

.googleMapImage {
  width: 20rem; /* Adjust width as needed */
  height: 20rem; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  margin-top: 1rem;
  align-items: right;
  display: flex;
    
}

/*social media icon styles*/
.social-media-icons {
  justify-content: center;
  display: space-between;
  gap: 3rem;
  margin: 1rem;
  transition: color 0.3s ease 0.3s;
  flex-wrap: no-wrap;
}

/* FontAwesome icon styles */
.icon a {
  color: white; /* Change this to your desired color */
  transition: transform 0.3s ease, color 0.3s ease; /* Add transition for transform and color */
  gap: 3rem;



    

    


  
}
.icon:hover {
  transform: scale(1.2); /* Magnify the icon on hover */
  gap: 3rem;

}

/* Import Lato font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

/* Apply Lato font globally */
body {
  font-family: 'Lato', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: scroll; 
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  

}



/*boxing phot styles*/
.boxPic {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  border-radius: 20px;
}

.boxcard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

}
.boxcard-box {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  border-radius: 20px;
} 



.carousel .slide img {
  transition: opacity 0.5s ease-in-out;
  opacity: 0; /* Adjust this value as needed */
  border-radius: 20px;
}

.carousel .slide.selected img {
  opacity: 1;
  border-radius: 20px;
}

/*social media icon styles*/

/* FontAwesome icon styles */
.icon {
  color: white; 
  text-decoration: none;/* Change this to your desired color */
  gap: 2rem;
  margin-left: 1rem; /* Add margin to the right */


}

.cardIcon{
  text-decoration: none;/* Change this to your desired color */
  transition: transform 0.3s ease, color 0.3s ease;
  margin-right: 1rem; /* Add margin to the right */
}

.cardIcon:hover {
  transform: scale(1.2); /* Magnify the icon on hover */
}


.logoTop{
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* schedule columns flex */
@media (min-width: 768px) {
  .schedule-fees-container {
    flex-direction: row;
    gap: 8rem; /* Add space between columns */
  }

  .schedule-column {
    order: 1;
    flex: 1;
  }

  .fees-column {
    order: 2;
    flex: 1;
  }
}

@media (max-width: 767px) {
  .schedule-fees-container {
    flex-direction: column;
   
    align-items: flex-start;

    gap: 1rem; /* Add space between columns */
  }

  .schedule-column {
    order: 1;
    flex: 1;
  }

  .fees-column {
    order: 2;
    flex: 1;
  }
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.grid-item {
  cursor: pointer;
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.2s;
}

.grid-item img:hover {
  transform: scale(1.05);
}



.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.finePrint {
  font-size: 0.67rem;
 
}
.fineBold {
  font-weight: bold;
  font-size: 0.8rem;
}


.clock {
  margin-right: 0.5rem
}









#arnisShowcase {
  position: relative;
  height: 100vh;
  width: 120vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
  overflow: hidden;
  margin-top: 1rem;
}


#arnisShowcase video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the video is behind the content */
}

@media (max-width: 768px) {
  #arnisShowcase {
    width: 100vw;
    height: 50vh; /* Adjust height for mobile */
    height: 50vh; /* Adjust height for mobile */
    margin-top: auto ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1rem;
  }

  #arnisShowcase video {
    height: auto; /* Adjust video height for mobile */
  }
}











#aboutShowcase {
  position: relative;
  height: 100vh;
  width: 120vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
  overflow: hidden;
  margin-top: 1rem;
}

#aboutShowcase video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the video is behind the content */
}

@media (max-width: 768px) {
  #aboutShowcase {
    width: 100vw;
    height: 50vh; /* Adjust height for mobile */
    height: 50vh; /* Adjust height for mobile */
    margin-top: auto ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1rem;
  }

  #aboutShowcase video {
    height: auto; /* Adjust video height for mobile */
  }
}






#boxShowcase {
  position: relative;
  height: 100vh;
  width: 120vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box; /* Ensure margin is included in height calculation */
  overflow: hidden;
  margin-top: 1rem;
}

#boxShowcase video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the video is behind the content */
}

@media (max-width: 768px) {
  #boxShowcase {
    z-index: -1; /* Ensure the video is behind the content */
    width: 100vw;
    height: 50vh; /* Adjust height for mobile */
    margin-top: auto ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1rem;
  }
  

  #boxShowcase video {
    height: auto; /* Adjust video height for mobile */
  }
}





@media (max-width: 767px) {
  .Karate,
  .boxing,
  .arnis {
    margin-top: -2rem;
  }
}


video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-play-button {
  display: none !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

#arnisShowcase video::-webkit-media-controls {
  display: none !important;
}

#arnisShowcase video::-webkit-media-controls-play-button {
  display: none !important;
}

#arnisShowcase video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

